/** @jsx jsx */
import { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { jsx } from "theme-ui"
import { motion } from "framer-motion"
import { Box, Card, Grid, Heading } from "@theme-ui/components"
import GatsbyImage from "../components/GatsbyImage"
import { FindSpace, LinkWrapper } from "x"

const TheContainer = motion.custom(Grid)
const ItemBox = motion.custom(Card)

export const PartnersBlock = () => {
	const data = useStaticQuery(
		graphql`
			query {
				craft {
					entries(section: [partners], site: "osg") {
						... on Craft_Partners {
							id
							title
							uri
							slug
							postDate
							body {
								totalPages
								content
							}
							featuredImage {
								url
								filename
								focalPoint {
									x
									y
								}
							}
							headshot {
								id
								url
								filename
								focalPoint {
									x
									y
								}
							}
						}
					}
				}
			}
		`
	)

	const partners = data.craft.entries

	const thelist = {
		hidden: {
			opacity: 1,
			scale: 0,
		},
		visible: {
			opacity: 1,
			scale: 1,
			transition: {
				when: "beforeChildren",
				staggerChildren: 0.1,
			},
		},
	}

	const theitem = {
		hidden: { y: 20, opacity: 0 },
		visible: { y: 0, opacity: 1 },
	}

	return (
		<Fragment>
			<TheContainer
				initial="hidden"
				animate="visible"
				variants={thelist}
				gap={4}
				columns={[2, null, 4]}
			>
				{partners.map(item => (
					<ItemBox
						key={item.id}
						variants={theitem}
						variant="bio"
						whileHover={{ scale: 1.065 }}
						transition={{ duration: 0.365 }}
					>
						<GatsbyImage
							filename={item.headshot[0].filename}
							alt={item.title}
						/>

						<Box
							p={3}
							sx={{
								textAlign: "center",
							}}
						>
							<Heading
								variant="caps"
								sx={{
									lineHeight: 1.1,
									fontFamily: "links",
									fontWeight: "500",
									fontSize: ".825em",
								}}
							>
								{item.title}
							</Heading>
						</Box>

						<LinkWrapper to={`/${item.uri}/`}>
							Read about {item.title}
						</LinkWrapper>
					</ItemBox>
				))}
			</TheContainer>
			<FindSpace />
		</Fragment>
	)
}
