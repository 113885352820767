/** @jsx jsx */
import { motion } from 'framer-motion'
import { jsx } from 'theme-ui'
import { MdKeyboardArrowRight as IconRight } from 'react-icons/md'

export const BouncingArrow = () => (
	<motion.span
		animate={{ x: 7 }}
		transition={{ yoyo: Infinity, duration: 0.315 }}
		sx={{
			display: 'inline-block'
		}}
	>
		<IconRight />
	</motion.span>
)
