import React from "react"
import { useLocalStorage } from "react-use"
import styled from "@emotion/styled"
import { Box, Button } from "@theme-ui/components"
import { MdBugReport } from "react-icons/md"

export const TheDebugBox = ({ children, ...props }) => {
	// const [isVisible, setIsVisible] = useLocalStorage(false)
	const [isVisible, setIsVisible] = useLocalStorage("isVisible", false)
	// useLocalStorage(
	//   "theme-ui-color-mode",
	//   "light"
	// )
	const toggleVisible = () => {
		setIsVisible(!isVisible)
	}

	const FixedBox = styled(Box)`
		position: fixed;
		left: 20px;
		bottom: 20px;
	`

	const StyledButton = styled(Button)`
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 2120;
		line-height: 0;
		padding: 0;
	`

	const StyledBox = styled(Box)`
		background: ${props.bg || "red"};
		display: ${isVisible ? "block" : "none"};
		padding: 3rem;
		pre {
			white-space: pre-wrap;
		}
		[data-debugger="on"] & {
			display: block;
			// background: purple;
		}
	`
	return (
		props.object && (
			<FixedBox>
				<StyledButton type="button" onClick={toggleVisible}>
					<MdBugReport size="2em" />
				</StyledButton>
				<StyledBox {...props}>
					<pre>{JSON.stringify(props.object, null, 2)}</pre>
				</StyledBox>
			</FixedBox>
		)
	)
}
