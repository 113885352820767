const headerpart = {
	display: "flex",
	px: 4,
	py: 0,
	// height: [tailwind.sizes[24], tailwind.sizes[10], tailwind.sizes[32]],
	zIndex: 2,
	position: "relative",
	// color: 'white',
	alignItems: "center",
	justifyContent: "space-between",
}

export default {
	initialColorMode: "light",
	colors: {
		text: "var(--tx_primary_fg)",
		background: "var(--tx_primary_bg)",
		icon_red: "#3bbad7",
		muted: "#f6f6f6",
		primary: "var(--tx_highlight)",
		navlinks: "var(--navlinks)",
		navlinkshover: "var(--navlinkshover)",

		primary_fg: "var(--tx_primary_fg)",
		primary_bg: "var(--tx_primary_bg)",
		inverted_fg: "var(--tx_inverted_fg)",
		inverted_bg: "var(--tx_inverted_bg)",
		fg_max: "var(--tx_fg_max)",
		fg_high: "var(--tx_fg_high)",
		fg_low: "var(--tx_fg_low)",
		fg_min: "var(--tx_fg_min)",
		highlight: "var(--tx_highlight)",
		highlight_hover: "var(--tx_highlight_hover)",
		highlight_accent: "var(--tx_highlight_accent)",
		secondary_highlight: "var(--tx_secondary_highlight)",

		modes: {
			dark: {
				text: "var(--dk_tx_primary_fg)",
				muted: "#333",
				background: "var(--dk_tx_primary_bg)",
				navlinks: "white",
				primary_fg: "var(--dk_tx_primary_fg)",
				primary_bg: "var(--dk_tx_primary_bg)",
				inverted_fg: "var(--dk_tx_inverted_fg)",
				inverted_bg: "var(--dk_tx_inverted_bg)",
				fg_max: "var(--dk_tx_fg_max)",
				fg_high: "var(--dk_tx_fg_high)",
				fg_low: "var(--dk_tx_fg_low)",
				fg_min: "var(--dk_tx_fg_min)",
				highlight: "var(--dk_tx_highlight)",
				highlight_hover: "var(--dk_tx_highlight_hover)",
				highlight_accent: "var(--dk_tx_highlight_accent)",
				secondary_highlight: "var(--dk_tx_secondary_highlight)",
			},
		},
	},
	fonts: {
		body: "Sentinel SSm A, Sentinel SSm B",
		heading: "Sentinel SSm A, Sentinel SSm B",
		links: "Gotham SSm A, Gotham SSm B",
		mono: "Menlo, monospace",
	},
	fontWeights: {
		body: 400,
		heading: 700,
	},
	letterSpacings: {
		wide: "0.2em",
		heading: "-.03em",
	},
	lineHeights: {
		body: 1.61618,
		heading: 0.9,
	},
	styles: {
		root: {
			// color: `text`,
			backgroundColor: "muted",
			fontFamily: "body",
			a: {
				textDecoration: "none",
				// color: "unset",
				transition: "all 0.3s ease-in-out",
				// "&:hover": {
				//   color: `primary`,
				//   textDecoration: `none`,
				// },
			},
		},
	},
	sizes: {
		container: 1200,
	},
	links: {
		primary: {
			color: "highlight",
			"&:hover": {
				color: "highlight_hover",
			},
		},
		breadcrumb: {
			variant: ["links.primary", "text.minicaps"],
			py: 3,
			display: "inline-block",
		},
		basic: {
			fontFamily: "condensed",
			textDecoration: "none",
		},
		nav: {
			fontFamily: "links",
			textTransform: "uppercase",
			letterSpacing: "wide",
			fontSize: "12px",
			fontWeight: "700",
			px: [2, 3],
			py: [2, 3],
			"&:hover": {
				color: "navlinkshover",
			},
			"&[data-active='true']": {
				color: "primary",
			},
			"&:last-of-type": {
				border: "1px solid currentColor",
				borderRadius: "15px",
				padding: "4px 8px",
				mx: [2, 3],
			},
		},
	},
	text: {
		bodytext: {
			lineHeight: "body",
			fontSize: [2, 3],
			maxWidth: "750px",
		},
		excerpt: {
			lineHeight: "body",
			fontStyle: "italic",
		},
		caps: {
			textTransform: "uppercase",
			letterSpacing: "wide",
		},
		minicaps: {
			textTransform: "uppercase",
			letterSpacing: "wide",
			lineHeight: 1.1,
			fontFamily: "links",
			fontWeight: "500",
			fontSize: ".825em",
		},
		heading: {
			fontFamily: "heading",
			fontWeight: "heading",
			lineHeight: "heading",
			letterSpacing: "heading",
			display: "block",
		},
		display: {
			// extends the text.heading styles
			variant: "text.heading",
			fontSize: [6, 7, 8],
			fontWeight: "display",
		},
		homedisplay: {
			fontSize: ["30px", "36px", "42px", "60px", "72px"],
			fontWeight: "400",
			lineHeight: "1.1",
			mt: 0,
			mb: 4,
			color: "white",
			textShadow: "0.12em 0.12em 0.12em rgba(0,0,0,0.2)",
			letterSpacing: "tight",
		},
	},
	buttons: {
		primary: {
			color: "background",
			bg: "highlight",
			fontFamily: "links",
			transition: "all 0.3s ease-in-out",
			cursor: "pointer",
			"&:hover": {
				bg: "highlight_hover",
			},
		},
		// burgerbutton: {
		//   bg: "transparent",
		// },
		secondary: {
			color: "background",
			bg: "secondary",
			transition: "all 0.3s ease-in-out",
			cursor: "pointer",
		},
		themeswitch: {
			padding: 2,
			lineHeight: 0,
			cursor: "pointer",
			background: "transparent",
		},
	},
	cards: {
		primary: {
			padding: 2,
			borderRadius: 4,
			boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
			bg: "text",
			color: "background",
			position: "relative",
		},
		bio: {
			borderRadius: 8,
			// padding: 3,
			overflow: "hidden",
			position: "relative",
			// "&:hover": {
			//   background: "#ffff00",
			//   transition: "background-color 0.3s ease-in-out",
			// },
			// "&:hover": {
			//   div: {
			//     bg: "text",
			//     color: "#ffff00",
			//     transition: "background-color 0.3s ease-in-out",
			//   },
			// },
		},
		compact: {
			padding: 1,
			borderRadius: 2,
			border: "1px solid",
			borderColor: "muted",
		},
	},
	forms: {
		label: {
			fontSize: ".0875em",
			variant: "text.caps",
			fontFamily: "links",
			fontWeight: 600,
			mb: 2,
		},
		input: {
			bg: "transparent",
			fontFamily: "body",
		},
		select: {
			fontFamily: "body",
		},
		slider: {
			bg: "muted",
		},
	},
	grids: {
		forms: {
			maxWidth: "100%",
			// gridTemplateColumns: 2,
			gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
			// gridTemplateColumns: "repeat(2, auto);",
			// gridTemplateColumns: "minmax(300px, 1fr) minmax(300px, 1fr)",
			// overflow: ''
		},
	},
	images: {
		bio: {
			// borderRadius: 3,
			// position: "relative",
			// "&:before": {
			//   content: '""',
			//   display: "block",
			//   paddingTop: "100%",
			// },
		},
	},
	layout: {
		header: {
			default: {
				...headerpart,
				// backgroundColor: "primary",
				a: {
					color: "navlinks",
				},
				// svg: {
				//   fill: "navlinks",
				// },
			},
			transparent: {
				...headerpart,
				background:
					"linear-gradient(to bottom, rgba(0,0,0,0.45) 0%, transparent 100%)",
				a: {
					color: "white",
				},
				"--navlinks": {
					color: "purple",
				},
			},
			noscrim: {
				...headerpart,
				background: "transparent",
				a: {
					color: "pink",
				},
				"--navlinks": {
					color: "maroon",
				},
			},
			fixedpage: {
				display: "flex",
				position: "fixed",
				px: 4,
				py: 0,
				zIndex: 2,
				alignItems: "center",
				justifyContent: "space-between",
				background: "transparent",
				".headerlinks": {
					display: "none",
				},
				"--theme-ui-colors-navlinks": "purple",
			},
		},
		footer: {
			fontSize: ".875rem",
			variant: "text.caps",
			fontFamily: "links",
		},
	},
}
