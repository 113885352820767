/** @jsx jsx */
import { jsx } from "theme-ui"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = props => (
	<StaticQuery
		query={graphql`
			query {
				images: allFile(filter: { fields: { ArticleImage: { eq: "true" } } }) {
					edges {
						node {
							id
							fields {
								parentId
								title
								slug
								uri
								extension
							}
							relativePath
							name
							childImageSharp {
								# sizes(maxWidth: 600) {
								#   ...GatsbyImageSharpSizes
								# }

								# resize(width: 240, height: 150, grayscale: true) {
								# 		src
								# 	}
								fixed(width: 240, height: 150) {
									...GatsbyImageSharpFixed
								}
								fluid(maxWidth: 400, quality: 80) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		`}
		render={data => {
			const image = data.images.edges.find(n => {
				return n.node.relativePath.includes(props.filename)
			})
			if (!image) {
				return null
			}

			// const imageSizes = image.node.childImageSharp.sizes
			const imageSizes = image.node.childImageSharp.fixed
			return <Img alt={props.alt} fixed={imageSizes} />
		}}
	/>
)

export default Image
