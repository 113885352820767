// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-articles-js": () => import("../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-pages-js": () => import("../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-partners-js": () => import("../src/templates/partners.js" /* webpackChunkName: "component---src-templates-partners-js" */),
  "component---src-page-templates-home-page-js": () => import("../src/pageTemplates/home-page.js" /* webpackChunkName: "component---src-page-templates-home-page-js" */),
  "component---src-page-templates-search-listings-js": () => import("../src/pageTemplates/search-listings.js" /* webpackChunkName: "component---src-page-templates-search-listings-js" */),
  "component---src-templates-basic-template-js": () => import("../src/templates/basicTemplate.js" /* webpackChunkName: "component---src-templates-basic-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

