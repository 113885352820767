/** @jsx jsx */
import { jsx } from "theme-ui"

export const TheContainer = props => (
	<div
		{...props}
		sx={{
			// variant: "styles.Container",
			//   bg: "red",
			mx: "auto",
			minWidth: 0,
			width: "100%",
			maxWidth: props.unconfined ? "unset" : "container",
			px: props.unconfined ? "unset" : 4,
		}}
	/>
)
