/** @jsx jsx */
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import { jsx } from "theme-ui"
import { Flex } from "@theme-ui/components"
import { LogoLockup, TheDebugBox, TheLink, TheSwitcher } from "x"
import _ from "lodash"
import { motion } from "framer-motion"
import { useMedia } from "react-use"
// AnimatePresence

const { NODE_ENV } = process.env

const ListLink = props => (
	<GatsbyLink
		to={props.to}
		sx={{
			variant: "links.nav",
		}}
		getProps={({ isPartiallyCurrent }) =>
			isPartiallyCurrent ? { "data-active": "true" } : null
		}
	>
		{props.children}
	</GatsbyLink>
)

const duration = 0.5

const variants = {
	intitial: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration: duration,
			delay: duration,
			when: "beforeChildren",
		},
	},
	exit: {
		opacity: 0,
		transition: { duration: duration },
	},
}

// const transitionStyles = {
// 	entering: {
// 		opacity: 0,
// 	},
// 	entered: {
// 		opacity: 1,
// 		transition: "opacity 500ms ease-in-out",
// 	},
// 	exiting: {
// 		opacity: 0,
// 		transition: "opacity 500ms ease-in-out",
// 	},
// }

export const TheHeader = ({ siteTitle, menuLinks, pageContext }) => {
	// const hasFixed = _.has(pageContext, "fixed")
	const hasHeaderVariant = _.has(pageContext, "headerVariant")
	const thevariant = hasHeaderVariant ? pageContext.headerVariant : "default"
	const isWide = useMedia("(min-width: 992px)")

	return (
		<motion.header
			sx={{
				variant: `layout.header.${thevariant}`,
				height: "var(--heading-height)",
			}}
			data-headertype={thevariant}
			key={thevariant}
			variants={variants}
			initial="initial"
			animate="enter"
			exit="exit"
		>
			<TheLink
				to="/"
				aria-label="Home Page"
				sx={{ color: "heading", px: 3, height: "41px" }}
			>
				<motion.div
					whileHover={{ scale: 1.065 }}
					whileTap={{ scale: 0.97 }}
					transition={{ duration: 0.365 }}
				>
					<LogoLockup w={isWide ? "300" : "240"} />

					<span className="sr-only" sx={{ display: "none" }}>
						{siteTitle}
					</span>
				</motion.div>
			</TheLink>

			<Flex
				className="headerlinks"
				sx={{
					alignItems: "center",
					variant: "styles.Navlinks",
					position: "relative",
					display: "flex",
					"@media screen and (max-width: 992px)": {
						display: "none",
					},
				}}
			>
				{menuLinks.map((item, i) => (
					<ListLink key={i} to={item.link}>
						{item.label}
					</ListLink>
				))}

				<TheSwitcher />
			</Flex>
			{NODE_ENV === "development" && (
				<TheDebugBox
					object={pageContext}
					// isDisplayed={false}
					sx={{
						position: "fixed",
						left: "20px",
						bottom: "20px",
						maxWidth: "40vw",
					}}
				/>
			)}
		</motion.header>
	)
}

TheHeader.propTypes = {
	siteTitle: PropTypes.string,
}

TheHeader.defaultProps = {
	siteTitle: ``,
}
