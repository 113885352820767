import React from "react"
import { TheLayout } from "x"
import { ColorMode, Styled } from "theme-ui"
import Global from "./src/styles/globals"

const transitionDelay = 500

export const wrapPageElement = ({ element, props }) => (
	<>
		<ColorMode />
		<Global />
		<Styled.root>
			<TheLayout {...props}>{element}</TheLayout>
		</Styled.root>
	</>
)

export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	if (location.action === "PUSH") {
		window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
	} else {
		const savedPosition = getSavedScrollPosition(location)
		window.setTimeout(
			() => window.scrollTo(...(savedPosition || [0, 0])),
			transitionDelay
		)
	}
	return false
}
