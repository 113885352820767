/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

/** @jsx jsx */
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Global from "../styles/globals"
import _ from "lodash"
import { motion, AnimatePresence } from "framer-motion"
import { jsx, ColorMode, Styled } from "theme-ui"
import { SlideMenu, TheContainer, TheFooter, TheHeader } from "x"
import { useMedia } from "react-use"
// import "./layout.css"

const duration = 0.5

const variants = {
	intitial: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration: duration,
			delay: duration,
			when: "beforeChildren",
		},
	},
	exit: {
		opacity: 0,
		transition: { duration: duration },
	},
}

// const transitionStyles = {
//   entering: {
//     opacity: 0,
//   },
//   entered: {
//     opacity: 1,
//     transition: "opacity 500ms ease-in-out",
//   },
//   exiting: {
//     opacity: 0,
//     transition: "opacity 500ms ease-in-out",
//   },
// }

export const TheLayout = ({ children, location, pageContext, ...props }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
					menuLinks {
						label
						link
					}
				}
			}
		}
	`)
	const isWide = useMedia("(min-width: 992px)")
	const hasHeaderVariant = _.has(pageContext, "headerVariant")
	const thevariant = hasHeaderVariant ? pageContext.headerVariant : "default"
	const hasPageBackground = _.has(pageContext, "pageBackground")
	const theBackground = hasPageBackground
		? pageContext.pageBackground
		: "background"
	// const hasShowBurgers = _.has(pageContext, "showBurgers")
	// const showmethemenu = hasShowBurgers
	// 	? pageContext.showBurgers === true
	// 	: !isWide

	return (
		<>
			<div
				id="tx-pg"
				sx={{
					display: "flex",
					flexDirection: "column",
					minHeight: "100vh",
					bg: theBackground,
				}}
				data-headertype={thevariant}
			>
				{pageContext.showBurgers || !isWide ? (
					<SlideMenu menuLinks={data.site.siteMetadata.menuLinks} />
				) : null}
				<TheHeader
					siteTitle={data.site.siteMetadata.title}
					menuLinks={data.site.siteMetadata.menuLinks}
					pageContext={pageContext}
				/>

				<AnimatePresence exitBeforeEnter>
					<motion.main
						key={location.pathname}
						variants={variants}
						initial="initial"
						animate="enter"
						exit="exit"
						sx={{
							display: "flex",
							flex: "1 1 auto",
							px: 3,
							pt: [3, 4, 5],
							pb: 3,
						}}
					>
						<TheContainer>{children}</TheContainer>
					</motion.main>
				</AnimatePresence>
				<TheFooter />
			</div>
		</>
	)
}

TheLayout.propTypes = {
	children: PropTypes.node.isRequired,
}
