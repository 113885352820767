/** @jsx jsx */
import { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { jsx } from "theme-ui"
import { Box, Grid } from "@theme-ui/components"
import { ArticleList, FindSpace } from "x"

export const ArticlesBlock = () => {
	const data = useStaticQuery(
		graphql`
			query {
				craft {
					entries(section: [articles]) {
						... on Craft_Articles {
							title
							uri
							slug
							postDate
							body {
								totalPages
								content
							}
							featuredImage {
								id
								url
								filename
								extension
								focalPoint {
									x
									y
								}
							}
						}
					}
				}
			}
		`
	)

	const posts = data.craft.entries
	return (
		<Fragment>
			<Grid
				gap={4}
				sx={{
					gridTemplateColumns: ["auto", null, "1fr 256px"],
				}}
			>
				<ArticleList items={posts} />
				<Box>
					<FindSpace />
				</Box>
			</Grid>
		</Fragment>
	)
}
