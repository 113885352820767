/** @jsx jsx */
import CoverImage from "./CoverImage"
import styled from "@emotion/styled"
import { jsx, useColorMode } from "theme-ui"
import { mq, elevation, offset, offsetXxl, gutter } from "../styles/presets"
import useBodyClass from "../hooks/useBodyClass"

const Container = styled(`div`)`
	margin-top: calc(67vh - ${gutter.default});
	padding: ${gutter.default};
	position: relative;

	${mq.tablet} {
		margin-left: ${offset};
		margin-top: 0;
		// max-width: 37.5rem;
		padding: ${gutter.tablet};
		position: relative;
	}

	${mq.desktop} {
		padding: ${gutter.desktop};
		padding-top: ${gutter.tablet};
	}

	${mq.xxl} {
		margin-left: ${offsetXxl};
	}
`
const Main = styled(`main`)`
	padding-top: ${gutter.default};
	position: relative;
	z-index: ${elevation.overlay};
`

export const FixedLayout = ({
	children,
	image,
	imageTitle,
	imageBackgroundColor,
	...props
}) => {
	const [colorMode] = useColorMode()
	useBodyClass(`tx-fixedpg`)
	useBodyClass(`tx-${colorMode}`)

	return (
		<Container>
			{image && (
				<CoverImage
					filename={image}
					backgroundColor={imageBackgroundColor ? imageBackgroundColor : false}
					title={imageTitle}
				/>
			)}
			<Main>{children}</Main>
		</Container>
	)
}
