export const locations = [
  {
    label: "Union Square/Flatiron",
    value: "union-square-flatiron",
  },
  {
    label: "Chelsea",
    value: "chelsea",
  },
  {
    label: "Tribeca",
    value: "tribeca",
  },
  {
    label: "Greenwich Village",
    value: "greenwich-village",
  },
  {
    label: "Columbus Circle",
    value: "columbus-circle",
  },
  {
    label: "Midtown East",
    value: "midtown-east",
  },
  {
    label: "Financial District",
    value: "financial-district",
  },
  {
    label: "Grand Central",
    value: "grand-central",
  },
  {
    label: "SoHo",
    value: "soho",
  },
  {
    label: "Penn Plaza",
    value: "penn-plaza",
  },
  {
    label: "Times Square",
    value: "times-square",
  },
  {
    label: "Downtown Brooklyn/DUMBO",
    value: "downtown-brooklyn-dumbo",
  },
  {
    label: "Midtown West",
    value: "midtown-west",
  },
  {
    label: "North Brooklyn",
    value: "north-brooklyn",
  },
  {
    label: "South Brooklyn",
    value: "south-brooklyn",
  },
  {
    label: "Rockefeller Plaza District",
    value: "rockefeller-plaza-district",
  },
  {
    label: "Wall Street/Lower Manhattan",
    value: "wall-street-lower-manhattan",
  },
  {
    label: "West Village/East Village",
    value: "west-village-east-village",
  },
]
