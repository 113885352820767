import { Link as GatsbyLink } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

export const LinkWrapper = ({ children, to, ...props }) => {
  const internal = /^\/(?!\/)/.test(to)

  const FullLink = styled(GatsbyLink)`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    overflow: hidden;
    text-indent: -9999px;
    background: transparent;
  `

  if (internal) {
    // const StyledLink = styled(FullLink)`
    // 	color: yellow;
    // `

    return (
      <FullLink to={to} className={props.className} {...props}>
        {children}
      </FullLink>
    )
  }
  return (
    <FullLink as="a" href={to} className={props.className} {...props}>
      {children}
    </FullLink>
  )
}
