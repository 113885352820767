/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"

export const TheFooter = ({ props }) => (
	<footer
		sx={{
			p: 3,
			zIndex: 1,
			variant: "layout.footer",
		}}
	>
		<div
			sx={{
				display: "grid",
				gridTemplateColumns: "repeat(auto-fit, minmax(256px, 1fr))",
				alignItems: "center",
				// maxWidth: 768,
				mx: "auto",
			}}
		>
			<Box p={2} sx={{ textAlign: ["center", "center", "left"] }}>
				355 Lexington Avenue, New York, NY 10017
			</Box>
			<Box p={2} sx={{ textAlign: ["center", "center", "right"] }}>
				© {new Date().getFullYear()} Office Space Guys
			</Box>
		</div>
	</footer>
)
