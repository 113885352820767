/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
export const TheLink = ({ ...props }) => {
  return (
    <Link
      {...props}
      activeClassName="active"
      sx={{
        // color: "accent",
        //   "&.active": {
        //     color: "secondary",
        //   },
        ":hover,:focus": {
          color: "primary",
          transition: "all 0.3s ease-in-out",
        },
      }}
    />
  )
}
