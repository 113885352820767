module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Sentinel SSm A","Sentinel SSm B","Gotham SSm A","Gotham SSm B"],"urls":["https://cloud.typography.com/6759554/8005812/css/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Office Space Guys","short_name":"OSG","start_url":"/","background_color":"#06BAD1","theme_color":"#06BAD1","display":"minimal-ui","icon":"src/images/osg-icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
